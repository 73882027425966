/**
 * Copyright 2021 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import React from "react";
import Login from './layout/Login';
import './theme/App.css';
import TopAppbar from './components/appbar/TopAppbar';
import { useSelector } from 'react-redux';
import Dashboard from './layout/Dashboard';

function App() {
  const isLoggedIn = useSelector((state) => state.isLoggedIn.value)
  const viewname = useSelector((state) => state.dashData.viewname);
  const user = useSelector((state) => state.isLoggedIn.user);

  return (
    <div className="App">
    <TopAppbar name={viewname} user={user}/>
    {isLoggedIn ? (
      <Dashboard />
      ) : (
      <Login />
      )}  
    </div>
  );
}

export default App;
