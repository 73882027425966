/**
 * @class Dashboard
 * @author Eric Grabowski 
 * @description Container class for displaying views
 * @copyright 2021, 2022 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import React, { useState, useEffect }  from "react";
import MainView from './views/MainView';
import LoadingView from './views/LoadingView';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import abierto from '../theme/abierto';
import config from '../config';
import axios from 'axios';




export default function Dashboard() {

  const enginestate = useSelector((state) => state.dashData.enginestate);
  const labels = [{"label": ""}];
  const [engine, setEngine] = useState(enginestate);
  const user = useSelector((state) => state.isLoggedIn.user);
  const status = config.serviceRoot+"/check";
  const key = 'CognitoIdentityServiceProvider.' + config.auth.userPoolWebClientId + '.' + user + '.' + 'idToken'; 
  const JWTToken = sessionStorage.getItem(key);
  const userURL = config.serviceRoot + "/user";

  const  fetchStatus = async () => {
    await axios.get(status, { headers: {"Authorization" : `Bearer ${JWTToken}` , "Content-Type": "application/json" } })
    .then((response) => {
      console.log(response.data)
      setEngine(response.data)
     })
     .catch(
       function (error) {
       console.error('Error: $(error)');
       setEngine(false);
        }
      )
  };

  useEffect(() => {
    fetchStatus();
    }, []);

    return (
            <Paper sx={{ backgroundColor: '#ffffff' }}>
            <Grid item xs={12}>
            <Box backgroundColor={abierto.white} display="flex" alignItems="center" justifyContent="center">
                <Container maxWidth={false} >
                  <Box py={4} backgroundColor={abierto.white}>
                  {engine ? (<MainView />) : (<LoadingView />)}  
                  </Box>
                </Container>
              </Box>
            </Grid>
          </Paper>
    )
}