/**
 * @class Cognito
 * @author Eric Grabowski 
 * @description Utility class that provides authentication to AWS Cognito
 * @copyright 2021, 2022 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import {Amplify} from 'aws-amplify';
import {Auth} from 'aws-amplify';
import config from '../../config';

Amplify.configure({
    Auth: {
    userPoolId: config.auth.userPoolId,
    region: config.auth.region,
    userPoolWebClientId: config.auth.userPoolWebClientId,
    authenticationFlowType: config.auth.authenticationFlowType,
    storage: sessionStorage
    }
  });

/**
 * @method userLogin
 * @argument username - Cognito username
 * @argument password - Users password
 * @description Attepts to authenticate user to an AWS Cognito userpool using the provided credentials
 */

export const userLogin = async ({ username, password }) => {
  var isAuth = false;
  try{ 
    await Auth.signIn(username, password);
    isAuth = true;   
  } catch (error)
  {
    isAuth = false;
  } 
    return new Promise((resolve, reject) => {
      if (isAuth) {
        resolve();
      } else {
        reject();
      }
    });
  }; 

/**
 * @method userLogout
 * @description Logs out any current Cognito user sessions
 */

  export const userLogout = async () => {
    var isLoggedout = false;
    try {
      await Auth.signOut();
      isLoggedout = true;
    } catch (error) {
        isLoggedout = false;
      }
      return new Promise((resolve, reject) => {
        if (isLoggedout) {
          resolve();
        } else {
          reject();
        }
      });
  };

  