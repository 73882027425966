/**
 * @class TopAppbar
 * @author Eric Grabowski 
 * @description A component for displaying an application bar at the top of the page
 * @copyright 2021, 2022 Kiwi Dreamer Consulting, LLC. All Rights Reserved.
 */

import React from "react";
import { useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { AccountMenu } from './AccountMenu';
import abierto from '../../theme/abierto';

export default function TopAppbar(props) {


    const clients = ['rutters', 'sheetz', 'ta']
    const isLoggedIn = useSelector((state) => state.isLoggedIn.value)
    const userName = useSelector((state) => state.isLoggedIn.user) 

    let clientLogo = require('../../assets/img/Logo_Header1.png')

    if (clients.indexOf(userName) !== -1) {
        clientLogo = require(`../../assets/img/logo_header_${userName}.png`)
    }

    let sx = { flexGrow: 0.98 }

    if (isLoggedIn) {
        sx = { flexGrow: 0.98, marginLeft: '200px' }
    }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color='secondary'>
        <Toolbar>
         <img src={require('../../assets/img/Logo_Header1.png')} alt="Abierto" />

         { isLoggedIn ? (<img src={clientLogo} alt={userName} className="logo" />) : (null)}
         <Typography variant="h6" component="div" align="center" sx={sx}>
            {props.name}
         </Typography>

          { isLoggedIn ? (<AccountMenu first={props.user} />) : (null)}          
        </Toolbar>
      </AppBar>
    </Box>
  );
}